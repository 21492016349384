<sm-overlay [backdropActive]="backdropActive$|async"></sm-overlay>
<div class="h-100 d-flex">
  <sm-experiment-hyper-params-navbar
    [class.minimized]="minimized"
    [hyperParams]="(hyperParamsInfo$| async)"
    [configuration]="configuration$ | async"
    [routerConfig]="(routerConfig$ | async)[minimized ? 5 : 6]"
    [routerParams]="routerParams$ | async"
    [disableAdd]="!(selectedExperiment?.status === 'created')"
  ></sm-experiment-hyper-params-navbar>
  <div class="hyper-col">
    <router-outlet></router-outlet>
  </div>
</div>
