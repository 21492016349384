<div class="d-flex align-items-center">
  <div class="d-flex align-items-center experiment-name-cont">
    <sm-experiment-type-icon-label class="experiment-icon" [showLabel]="false" [type]="experiment?.type" iconClass="l-40">
    </sm-experiment-type-icon-label>
    <sm-inline-edit
      #editName
      class="edit-name"
      [minLength]="2"
      [required]="true"
      [originalText]="infoData?.name || experiment?.name"
      [editable]="editable"
      (textChanged)="onNameChanged($event)"
      (inlineActiveStateChanged)="editExperimentName($event)"
    >
      <div class="experiment-name">
        <span>{{experiment?.name}}</span>
      </div>
    </sm-inline-edit>
  </div>
  <div class="d-flex align-items-center ml-auto">
    <div class="shared line-item"
         *ngIf="(!minimized) && shared"
    ><i class="fas fa-link p-1"></i></div>
    <sm-id-badge class="mr-3" [id]="experiment?.id" (copied)="copyToClipboard()" *ngIf="!viewId"></sm-id-badge>
    <span class="comment line-item"
          [delay]="1000" [action]="'leave'"
          (smHesitate)="menuHesitate.hesitateStatus && menu.closed.emit()"
    >
      <i class="al-icon  pointer"
         #commentElement
         [ngClass]="infoData?.comment ? 'al-ico-task-desc' : 'al-ico-task-desc-outline'"
         [matMenuTriggerFor]="menu"
         [delay]="500"
         [action]="'enter'"
         (smHesitate)="commentElement.click(); menuHesitate.hesitateStatus = true"
         (click)="false"
      ></i>
    </span>
    <mat-menu #menu="matMenu" backdropClass="hello" [hasBackdrop]="false" class="line-item" yPosition="above">
      <sm-experiment-info-edit-description
        #menuHesitate="hesitate"
        [delay]="1000"
        [action]="'leave'"
        (smHesitate)="menu.closed.emit();"
        [selectedExperiment]="infoData"
        (onDescription)="onDescriptionHandler()"
      ></sm-experiment-info-edit-description>
    </mat-menu>
    <div *ngIf="minimized" (click)="maximizedClicked.emit()"
         smTooltip="View Full Screen" class="d-flex fullscreen line-item">
      <i class="al-icon al-ico-info-max pointer"></i>
    </div>

    <div class="line-item" (click)="minimizeClicked.emit()"
            *ngIf="experiment && (!minimized) && !isSharedAndNotOwner && !isPipeline"
            smTooltip="View in experiment table">
      <i class="al-icon al-ico-info-min al-color pointer"></i>
    </div>
    <sm-experiment-menu-extended class="line-item"
      *ngIf="showMenu && experiment"
      [experiment]="experiment"
      [selectedExperiment]="experiment"
      [tagsFilterByProject]="tagsFilterByProject$ | async"
      [selectedDisableAvailable]="selectedDisableAvailable"
      [selectedDisableAvailableIsMultiple]="false"
      [projectTags]="projectTags$ | async"
      [companyTags]="companyTags$ | async"
      [minimizedView]="minimized"
      [isSharedAndNotOwner]="isSharedAndNotOwner"
      [numSelected]="1"
      (tagSelected)="addTag($event)"
    ></sm-experiment-menu-extended>
    <div *ngIf="minimized" (click)="closeInfoClicked.emit()" class="d-flex align-items-center line-item" data-id="Cross Button">
      <i class="al-icon al-ico-dialog-x pointer"></i>
    </div>
  </div>
</div>
<div class="middle-col">
  <sm-tag-list
    *ngIf="editable; else readOnlyTags"
    class="w-100"
    [class.menu-open]="!!tagMenu && tagMenu.trigger.menuOpen"
    [sysTags]="systemTags"
    [tags]="experiment?.tags"
    (add)="openTagMenu($event)"
    (remove)="removeTag($event)"></sm-tag-list>
  <ng-template #readOnlyTags>
    <sm-tag-list
      class="w-100"
      [sysTags]="systemTags"
      [tags]="experiment?.tags"></sm-tag-list>
  </ng-template>
  <sm-menu
    #tagMenu
    [showCart]="false"
    [showButton]="false"
    [showOverlay]="false"
    (menuClosed)="tagsMenuClosed()"
  >
    <sm-tags-menu
      class="light-theme"
      #tagsMenuContent
      [tags]="experiment?.tags"
      [tagsFilterByProject]="tagsFilterByProject$ | async"
      [projectTags]="projectTags$ | async"
      [companyTags]="companyTags$ | async"
      (tagSelected)="addTag($event)"
    ></sm-tags-menu>
  </sm-menu>
</div>
