<div
  class="pointer id-number"
  [smTooltip]="'Copy full ID\n' + id"
  customClass="break-line"

  ngxClipboard
  [cbContent]="id"
  (cbOnSuccess)="copied.emit()"
>
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="16" viewBox="0 0 20 16">
    <path class="id-rect" d="M20,16H2C.89,16,0,15.11,0,14V2C0,.89,.89,0,2,0H20V16Z"></path>
    <path  class="id-text"
           d="M7,12h-2V4h2V12ZM11.5,4h-3.5V12h3.5c1.93,0,3.5-1.57,3.5-3.5v-1c0-1.93-1.57-3.5-3.5-3.5Zm1.5,4.5c0,.83-.67,1.5-1.5,1.5h-1.5V6h1.5c.83,0,1.5,.67,1.5,1.5v1Z"></path>
  </svg><span *ngIf="id" class="hash">{{id?.slice(0, 8)}}<span class="three-dots">...</span></span>
</div>
