<ng-template
  let-saving="saving" let-editable="editable"
  let-configurationObjectName="configurationObjectName"
  [ngTemplateOutletContext]="{ saving: saving$ | async,
                               editable: editable$ | async,
                               configurationObjectName: selectedConfigObj$ | async}"
  [ngTemplateOutlet]="template" #template>
  <sm-editable-section [editable]="false">
    <sm-section-header [label]="configurationObjectName | replaceViaMapPipe:sectionReplaceMap | uppercase"></sm-section-header>
    <sm-labeled-row label="TYPE">{{formData?.type === 'legacy' ? '' : formData?.type}}</sm-labeled-row>
    <sm-labeled-row label="DESCRIPTION">{{formData?.description}}</sm-labeled-row>
  </sm-editable-section>

  <!-- **************** CONFIGURATION OBJECT **************** -->
  <sm-editable-section class="editable-design"
                       #prototext
                       [saving]="saving"
                       [editable]="editable"
                       [disableInEditMode]="true"
                       [disableEditable]="true"
                       (cancelClicked)="cancelModelChange()"
                       (activateEditClicked)="activateEditChanged('prototext'); editPrototext()">
    <sm-section-header label="CONFIGURATION OBJECT"></sm-section-header>
    <sm-scroll-textarea
      class="flex-grow-1"
      [editable]="editable && prototext.inEditMode"
      [formData]="formData === null ? null : formData?.value"
      [showSpinner]="formData === null"
    >
      <button extra-buttons
              *ngIf="formData?.value && editable"
              class="btn-dark-fill terminal mr-3 flex-1"
              (click)="prototext.editModeChanged(true)">EDIT</button>
      <button extra-buttons
              *ngIf="formData?.value && editable"
              class="btn-dark-fill terminal mr-3 flex-1"
              (click)="clearPrototext()">
        CLEAR
      </button>
    </sm-scroll-textarea>
  </sm-editable-section>
</ng-template>
