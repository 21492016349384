<sm-dialog-template header="MOVE TO PROJECT" [iconClass]="'al-ico-move-to'">
  <div class="message">
    <b *ngIf="reference" [smTooltip]="reference.length > 80 ? reference : undefined">
      {{reference.length > 80 ? (reference | slice:0:77) + '...' : reference }}
    </b> will be moved
    <span *ngIf="currentProjectInstance"> from
      <b
        [smTooltip]="currentProjectInstance.name.length > 80 ? currentProjects[0] : undefined">
        {{currentProjectInstance.name.length > 80 ? (currentProjectInstance.name | slice:0:77) + '...' : currentProjectInstance.name}}
      </b>
    </span> to the selected project.
  </div>
  <ng-form #moveToForm="ngForm" (submit)="closeDialog(true)" class="light-theme">
    <mat-form-field class="w-100" appearance="outline">
      <mat-label>Project</mat-label>
      <input matInput
             type="text"
             [matAutocomplete]="auto"
             [(ngModel)]="formData.project"
             name="projectName"
             placeholder="Search for project path"
             #projectInputRef
             #projectInput="ngModel"
             required
             minlength="3"
             (keydown.enter)="projectInput.control.markAsTouched() "
             smUniqueNameValidator
             [existingNames]="readOnlyProjects$ | async"
             (click)="filterText && clear(); projectInputRef.blur(); projectInputRef.focus()"
      >
      <span matSuffix *ngIf="(!isAutoCompleteOpen) && (!formData.project?.value) && formData.project" class="creat-new-suffix">(Create New)</span>
      <i matSuffix [class]="'fa mr2 ' + (filterText ? 'fa-times pointer' :  'fa-search')" (click)="filterText && clear(); projectInputRef.focus();"
         smClickStopPropagation></i>
      <mat-error *ngIf="projectInput?.errors?.required">Please provide a Project</mat-error>
      <mat-error *ngIf="projectInput?.errors?.minlength">Please provide a name longer than 3 characters</mat-error>
      <mat-error *ngIf="projectInput?.errors?.uniqueName && !currentProjects.includes(formData.project?.value)">Please provide a different name as this project name is taken as an Example project</mat-error>
<!--      TODO: Need another validator for this val error-->
      <mat-error *ngIf="formData.project && currentProjects.includes(formData.project.value)">{{isMulti? (type | titlecase)+'s are' : (type| titlecase) + ' is'}} already in the selected project</mat-error>
      <mat-autocomplete #auto="matAutocomplete"
                        name="ExperimentProject"
                        class="light-theme"
                        classList="light-theme"
                        (opened)="setIsAutoCompleteOpen(true)"
                        (closed)="setIsAutoCompleteOpen(false)"
                        [displayWith]="displayFn"
                        autoActiveFirstOption
      >
        <mat-option
          class="item"
          *ngIf="filterText && isNewName"
          [value]="filterText"
        >"{{filterText}}" <span class="new">(Create New)</span></mat-option>
        <mat-option *ngFor="let project of filteredProjects | async" [value]="project" [smTooltip]="project.label"  smShowTooltipIfEllipsis> <div [innerHTML]="project.label | highlightSearchText:projectInput.value"></div></mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </ng-form>
  <div class="buttons">
    <button [disabled]="moveToForm.invalid" (click)="closeDialog(true)" cdkFocusInitial
            class="btn btn-neon yes-button" data-id="MoveButton" #moveButton>
      MOVE
    </button>
    <button (click)="closeDialog(false)" class="btn btn-outline-neon" data-id ="CancelButton">
      CANCEL
    </button>

  </div>
</sm-dialog-template>
