<div class="d-flex justify-content-between">
  <div *ngIf="showAllProjectsToggle" class="center-buttons-container">
    <sm-button-toggle
      [value]="isAllProjects"
      [options]="allProjectsdModeOptions"
      (valueChanged)="onAllProjectsChanged($event)"
    >
    </sm-button-toggle>
  </div>

  <div class="d-flex align-items-center">
    <div
      *ngIf="searchActive"
      class="search-container open">
      <sm-search
        #search
        class="light-theme"
        placeholder="Search for models"
        [value]="searchValue"
        (valueChanged)="onSearchValueChanged($event)"
        (focusout)="onSearchFocusOut()">
      </sm-search>
    </div>
  </div>

</div>
