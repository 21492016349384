<mat-expansion-panel expanded="true" *ngIf="inputModels?.length || editable">
  <mat-expansion-panel-header collapsedHeight="40px" expandedHeight="40px" class="active">
    INPUT MODELS
  </mat-expansion-panel-header>
  <div *ngIf="noItemsMode; else hasItems"
       queryParamsHandling="preserve"
       class="artifact-container pointer"
       [routerLink]="'input-model/input-model'"
       [class.selected]="true"
  >
    <i class="icon sm al-ico-import"></i> Input Model
  </div>
  <ng-template #hasItems>
    <div
      *ngFor="let model of inputModels"
      queryParamsHandling="preserve"
      class="artifact-container pointer"
      [routerLink]="'input-model/' + (model.id | safeUrlParameter)"
      [class.selected]="model.id === selectedArtifactKey && activeSection === ACTIVE_SECTIONS['input-model']"
    >
      <i class="icon sm al-ico-import"></i>
      <span class="ellipsis">{{model.taskName}}</span>
    </div>
  </ng-template>
</mat-expansion-panel>

<mat-expansion-panel expanded="true" *ngIf="outputModels?.length">
  <mat-expansion-panel-header collapsedHeight="40px" expandedHeight="40px" class="active">
    OUTPUT MODELS
  </mat-expansion-panel-header>
  <div
    *ngFor="let model of outputModels"
    queryParamsHandling="preserve"
    class="artifact-container pointer"
    [routerLink]="'output-model/' + (model.id | safeUrlParameter)"
    [class.selected]="model.id === selectedArtifactKey && activeSection === ACTIVE_SECTIONS['output-model']"
  >
    <i class="icon sm al-ico-export"></i>
    <span class="ellipsis">{{model.taskName}}</span>
  </div>
</mat-expansion-panel>

<mat-expansion-panel expanded="true" *ngIf="dataAuditArtifacts?.length > 0">
  <mat-expansion-panel-header collapsedHeight="40px" expandedHeight="40px" class="active">
    DATA AUDIT
  </mat-expansion-panel-header>
  <div *ngFor="let artifact of dataAuditArtifacts; trackBy: trackByFn"
       class="artifact-container pointer"
       queryParamsHandling="preserve"
       [routerLink]="'artifact/' + (artifact.key | safeUrlParameter) + '/' + (artifact.mode | safeUrlParameter)"
       [class.selected]="selectedArtifactKey === artifact.key && activeSection === ACTIVE_SECTIONS['artifact']"
  >
    <i class="sm al-icon al-ico-data-audit al-color" [class]="(selectedArtifactKey === artifact.key ? 'white' : 'blue-400')"></i>{{artifact.key}}
  </div>
</mat-expansion-panel>
<mat-expansion-panel [expanded]="true" *ngIf="otherArtifacts?.length > 0">
  <mat-expansion-panel-header collapsedHeight="40px" expandedHeight="40px" class="active">
    OTHER
  </mat-expansion-panel-header>
  <div *ngFor="let artifact of otherArtifacts; trackBy: trackByFn"
       class="artifact-container pointer"
       queryParamsHandling="preserve"
       [routerLink]="'other/' + (artifact.key | safeUrlParameter) + '/' + (artifact.mode | safeUrlParameter)"
       [class.selected]="selectedArtifactKey === artifact.key && activeSection === ACTIVE_SECTIONS['other']"
  >
    <i class="sm al-icon al-ico-data-audit al-color" [class]="(selectedArtifactKey === artifact.key ? 'white' : 'blue-400')"></i>
    <span class="ellipsis">{{artifact.key}}</span>
  </div>
</mat-expansion-panel>
