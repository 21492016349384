<ng-template
  let-saving="saving" let-editable="editable"
  let-experimentName="experimentName" let-modelLabels="modelLabels"
  [ngTemplateOutletContext]="{ saving: saving$ | async,
                               editable: editable$ | async,
                               experimentName: selectedExperiment$ | async,
                               modelLabels: modelLabels$ | async}"
  [ngTemplateOutlet]="template" #template>
<sm-editable-section #modelSection
                     *ngIf="(model?.id || editable)"
                     [saving]="saving"
                     [editable]="editable && !outputMode"
                     [hideSaveButton]="true"
                     (cancelClicked)="cancelModelChange()"
                     (activateEditClicked)="activateEditChanged()">
  <sm-experiment-models-form-view
    #experimentModelForm
    [editable]="editable && modelSection.inEditMode"
    [model]="model"
    [experimentName]="experimentName"
    [projectId]="modelProjectId"
    [modelLabels]="modelLabels"
    [source]="source"
    [showCreatedExperiment]="!outputMode"
    (modelSelected)="onModelSelected($event); modelSection.unsubscribeToEventListener()">
  </sm-experiment-models-form-view>
</sm-editable-section>
<sm-editable-section class="editable-design"
                     [saving]="saving"
                     [editable]="false"
                     [disableInEditMode]="true"
                     containerClass="h-100"
                     (cancelClicked)="cancelModelChange()">
  <sm-section-header *ngIf="model?.id" label="MODEL CONFIGURATION"></sm-section-header>
  <sm-scroll-textarea
    class="flex-grow-1"
    [editable]="false"
    [formData]="inputDesign"
  ></sm-scroll-textarea>
</sm-editable-section>
</ng-template>
