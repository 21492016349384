<div class="title">{{titleText}}</div>
<sm-search
  [value]="searchText"
  [minimumChars]="0"
  [debounceTime]="0"
  placeholder="Search parameters"
  class="underline-search"
  (valueChanged)="searchQ($event)"
></sm-search>
<div class="actions">
  <div data-id="Clear Selection" class="pointer" (click)="onClearSelection()">Clear Selection</div>
  <ng-content></ng-content>
</div>
<div class="list">
  <mat-expansion-panel
    *ngFor="let category of (itemsObjectList | keyvalue: compareByKey); trackBy: trackByKeyFn"
    [expanded]="searchText?.length > 0"
    [hidden]="(category.value | advancedFilter:searchText).length === 0"
    togglePosition="before"
  >
    <mat-expansion-panel-header expandedHeight="40px" collapsedHeight="40px" (click)="toggleExpand(category.key)">
      <div class="ellipsis item-key" [smTooltip]="renameMap[category.key]" [matTooltipShowDelay]="500">
        {{renameMap[category.key] || category.key}}
      </div>
    </mat-expansion-panel-header>
    <ng-template matExpansionPanelContent>
      <div *ngFor="let item of category.value | filter:searchText:'value'; trackBy: trackByValFn"
         class="item pointer"
         [class.disabled]="cb.disabled"
         [smTooltip]="!item.checked && selectionLimitReached? 'You can only select up to ' + limitSelection + ' parameters' : ''"
         customClass="parameter-tooltip"
         (click)="!cb.disabled && toggleParamToDisplay(category.key + '.' + item.value, item.checked)">
        <mat-checkbox #cb
                      smClickStopPropagation
                      [ngModel]="item.checked"
                      [disabled]="selectionLimitReached && !item.checked"
                      (ngModelChange)="toggleParamToDisplay(category.key + '.' + item.value, item.checked)"
        ></mat-checkbox>
        <label class="ellipsis">{{item.value}}</label>
      </div>
    </ng-template>
  </mat-expansion-panel>
</div>
