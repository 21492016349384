  <sm-editable-section [disableInEditMode]="true" [editable]="false" *ngIf="artifact">
    <div>
      <sm-labeled-row label="FILE PATH">
        <a *ngIf="artifact?.uri && !isLocalFile && isLinkable" (click)="linkClicked($event)" href="">{{artifact?.uri}}</a>
        <span class="uri" *ngIf="!isLinkable">{{artifact?.uri}}</span>
        <i *ngIf="artifact?.uri && !isLocalFile && !isLinkable"
           class="pointer fa fa-download ml-2"
           (click)="artifactFilePathClicked(artifact.uri)"
        ></i>
        <sm-copy-clipboard *ngIf="artifact?.uri && isLocalFile"
                           label="" [clipboardText]="artifact.uri"
                           class="pl-2"
                           [hideBackground]="true"
        ></sm-copy-clipboard>
      </sm-labeled-row>
      <sm-labeled-row label="FILE SIZE">{{artifact?.content_size | filesize : fileSizeConfigStorage  || ''}}</sm-labeled-row>
      <sm-labeled-row label="HASH">{{artifact?.hash}}</sm-labeled-row>
      <sm-labeled-row *ngFor="let data of artifact?.display_data" [label]="data[0]| uppercase">{{data[1]}}</sm-labeled-row>
    </div>
  </sm-editable-section>
  <sm-editable-section class="editable-design"
                       [editable]="false"
                       [disableInEditMode]="true"
                       containerClass="h-100">
    <sm-section-header label="PREVIEW"></sm-section-header>
    <sm-scroll-textarea
      class="flex-grow-1"
      [editable]="false"
      [formData]="artifact?.type_data?.preview"
    ></sm-scroll-textarea>
  </sm-editable-section>


